import React from "react"

export default function ProjectBlocks(data) {

  function composeImages(srcImageArray, dataImageArray) {
    return dataImageArray.map((dataImage, index) => {
      const extractedImages = srcImageArray.map((srcImage, index) => {
        if(srcImage.node.name === dataImage.image) {
          return (
            <div className="pb-4" key={index}>
              <img className={dataImage.shadow ? 'shadow-sm border rounded' : undefined} src={srcImage.node.publicURL} alt={srcImage.node.name} key={index} />
              {dataImage.caption && 
                <div className="col-12 col-lg-10 pt-2 pl-0">
                  <p><small><em>{dataImage.caption}</em></small></p>
                </div>
              }
            </div>
          );
        }
        return null;
      })
      return extractedImages;
    })
  }

  function compose2ColumnImages(srcImageArray, dataImageArray) {
    return dataImageArray.map((dataImage, index) => {
      const extractedImages = srcImageArray.map((srcImage, index) => {
        if(srcImage.node.name === dataImage.image) {
          return (
            <div className="col-12 col-md-6 pb-4" key={index}>
              <img src={srcImage.node.publicURL} alt={srcImage.node.name} key={index} />
              {dataImage.caption && <p className="pt-2"><small>{dataImage.caption}</small></p>}
            </div>
          );
        }
        return null;
      })
      return extractedImages;
    })
  }

  return (
    <>
    {
      data.projectBlocks.map((project, index) => {
        const projectName = <h2 className="pb-2 bold">{project.projectName}</h2>;
        const blocks = project.blocks.map((block, index) => {
          let newBlock;
          switch(block.layout) {
            case "left":
              newBlock = <div key={index} className="row">
                <div className="col-12 col-lg-10 col-xl-9 pb-2">
                  <div dangerouslySetInnerHTML={ {__html: block.item} } />
                </div>
                <div className="col-12">
                  {composeImages(data.projectImages, block.images)}
                </div>
              </div>
              break;
            case "right":
              newBlock = <div key={index} className="row">
                <div className="col-12 col-lg-10">
                  <p>Right layout switch</p>
                  <p>{block.item}</p>
                </div>
                <div className="col-12">
                  {composeImages(data.projectImages, block.images)}
                </div>
              </div>
              break;
            case "2-col":
              newBlock = <div key={index} className="row">
                <div className="col-12 col-lg-10 pb-2">
                  <div dangerouslySetInnerHTML={ {__html: block.item} } />
                </div>
                {compose2ColumnImages(data.projectImages, block.images)}
              </div>
              break;
            case "grid-2-col":
              newBlock = <div key={index} className="row">
                {block.items.map((subBlock, index) => {
                  return (
                    <div className="col-12 col-lg-6 pb-2" key={index}>
                      <div dangerouslySetInnerHTML={ {__html: subBlock.item} } />
                      <div className="pb-4">
                        {composeImages(data.projectImages, subBlock.images)}
                      </div>
                    </div>
                  )
                })}
              </div>
              break;
            case "video":
              newBlock = <div key={index} className="row">
                <div className="col-12 col-lg-10 pb-2">
                  <div dangerouslySetInnerHTML={ {__html: block.item} } />
                </div>
                <div className="col-12">
                  <div className="video-wrapper">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/fvtqaLucCqI?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                  </div>
                </div>
              </div>
              break;
            default:
              newBlock = <div key={index}>
                <div className="col-12 col-lg-10">
                  <p>Default layout switch</p>
                  <p>{block.item}</p>
                </div>
                <div className="col-12">
                  {composeImages(data.projectImages, block.images)}
                </div>
              </div>
          }
          return newBlock;
        })
        return (
          <div key={index} className="border-top pt-5">
            <div className="row">
              <div className="col-10">{projectName}</div>
            </div>
            {blocks}
          </div>
        );
      })
    }
    </>
  )
}