import React, { useState } from "react"
import { Link } from "gatsby"
import ProjectHeader from "./ProjectHeader"
import ProjectBlocks from "./ProjectBlocks"
import Login from "./Login"
import { getUser, handleLogin } from "../services/auth"

export default function ProjectBody(data) {
  // State hooks
  const [authInput, setAuthInput] = useState('');
  const [failureMessage, setFailureMessage] = useState(false);
  const [authSubmission, setAuthSubmission] = useState('');

  const projectMeta = data.projectData.dataJson.meta[0];
  const projectBlocks = data.projectData.dataJson.projects;
  const projectImages = data.projectData.allFile.edges;
  const projectTitles = data.projectData.allDataJson.edges;

  const projectLinksFiltered = projectTitles.filter(project => project.node.meta[0].url !== projectMeta.url);

  const handleInputChange = (e) => {
    setAuthInput(e.target.value);
    if (failureMessage === true) {
      setFailureMessage(false);
    }
  }

  const authenticateLogin = (e) => {
    e.preventDefault();
    const loginResult = handleLogin(authInput);
    if(loginResult === false) {
      setAuthInput('');
      document.getElementById('login-form').reset();
      setFailureMessage(true);
    } else {
      setAuthSubmission(authInput);
    }
  }

  const userAuthCredentials = getUser();

  if (userAuthCredentials.zoots === 'blisterinthesun') {
    return (
      <article className="content-body content-body--project pt-5 pt-lg-6">
        <article className="project-block row-g row-g--project">
          <ProjectHeader 
            projectTitle={projectMeta.name}
            projectId={projectMeta.id}
            projectIntroduction={data.projectData.dataJson.introduction[0]}
            projectImages={projectImages}
          />
          <ProjectBlocks 
            projectBlocks={projectBlocks} 
            projectImages={projectImages} 
          />
        </article> 
        <article className="project-footer-wrap mt-5">
          <div className="project-footer row-g row-g--project">
            <h4 className="mb-0">Other projects</h4>
            <ul className="list-nostyle mt-4 mb-0">
              { projectLinksFiltered.map((project, index) => {
                return (
                  <li key={index}>
                    <Link to={`/work/${project.node.meta[0].url}`}>{project.node.meta[0].name}</Link>
                  </li>
                )
              }) }
            </ul>
          </div> 
        </article>
      </article>
    )
  } else {
    return (
      <Login 
        authenticateLogin={authenticateLogin} 
        handleInputChange={handleInputChange} 
        authInput={authInput} 
        failureMessage={failureMessage}
      />
    )
  }
}