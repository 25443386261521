import React from "react"

export default function Login(props) { 
  return (
    <>
      <section className="content-body content-body--project pt-6 pb-5">
        <article className="intro row-g">
          <div className="container-fluid">
            <div className="row pb-3 pb-lg-4">
              <div className="col-12 col-md-8 offset-md-2">
                <p>This page is restricted. Enter the password to continue.</p>
                <form id="login-form">
                  <input type="text" placeholder="password" className="form-control mb-3" name="password" value={props.authInput} onChange={props.handleInputChange} />
                  <button type="submit" className="btn btn-primary" onClick={props.authenticateLogin}>Submit</button>
                  {props.failureMessage ? <div className="mt-3 alert alert-danger">Incorrect password. Try again or contact Geoff for assistance.</div> : ''}
                </form>
              </div>
            </div>
          </div>
        </article>
      </section>
    </>
  )
}

