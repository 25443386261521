import React from "react"

export default function ProjectHeader(data) {

  function getSingleImage(srcImageArray, imageName) {
    const findImage = srcImageArray.find(image => image.node.name === imageName);
    return findImage.node.publicURL;
  }

  return (
    <header className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-10 col-xl-9 px-0 py-5 pt-lg-0 pb-lg-5">
          <div className="d-flex flex-column justify-content-start align-items-start flex-md-row align-items-md-center mb-3">
            <h1 className="mb-0">{data.projectTitle}</h1>
          </div>
          <div dangerouslySetInnerHTML={ {__html: data.projectIntroduction.description} } />
          {data.projectId === 'twif' && <a href="https://thisweekinfood.ca/" target="_blank" className="btn btn-primary" rel="noreferrer">Visit This Week in Food</a>}
        </div>
        {data.projectIntroduction.leadImage && <div className="col-12 px-0 pb-5"><img src={getSingleImage(data.projectImages, data.projectIntroduction.leadImage)} alt={data.projectTitle} className="pt-4 pb-4" /></div>}
      </div>
    </header>
  )
}