export const isBrowser = () => typeof window !== 'undefined';

export const getUser = () => {
  return isBrowser() && window.localStorage.getItem("gaUser") ? JSON.parse(window.localStorage.getItem("gaUser")) : {} ;
}

const setUser = user => window.localStorage.setItem("gaUser", JSON.stringify(user));

export const handleLogin = zoots => {
  if (zoots === "blisterinthesun") {
    setUser({
      zoots: "blisterinthesun",
    });
    return true;
  }
  return false;
}

export const isLoggedIn = () => {
  const user = getUser();
  return !!user.zoots;
}

export const logout = callback => {
  setUser({});
  callback();
}